import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

document.addEventListener("DOMContentLoaded", () => {

    mapboxgl.accessToken = 'pk.eyJ1Ijoibm9vcmRlcm1lZXIiLCJhIjoiY2xjaDg4OTJjMGIzNzN3czVna2JxZHhweCJ9.ofmUlkAPX5za2_SXAmafRA';

    const map = new mapboxgl.Map({
        container: 'map',
        style: 'mapbox://styles/mapbox/dark-v11?optimize=true',
        center: [8.537145398056964, 47.36524161752635],
        zoom: 12,
    });

    new mapboxgl.Marker({ color: '#00b2bf' })
        .setLngLat([8.537145398056964, 47.36524161752635])
        .addTo(map);

});
